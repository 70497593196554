import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SystemSettingsService } from '@core/services/system-settings.service';

export const edaraSetupGuard: CanActivateFn = async () => {
    const systemSettingsService = inject(SystemSettingsService);
    const router = inject(Router);
    const isEdaraSetupCompleted = await systemSettingsService.EdaraSetupCompleted();
    if (!isEdaraSetupCompleted) {
        router.navigate(['/system/edara-setup']);
    }
    return isEdaraSetupCompleted;
};


